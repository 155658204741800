import { patchLatestTestKitReceived } from 'api/User/api';
import { patchTestKitReceived } from 'api/Shipment/api';

import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const useChangeTestKitReceived = (onUpdated: (withKitId: string, testKitReceived: boolean) => void) => {
  const { t } = useTranslation();

  const [ isLoading, setIsLoading ] = useState(false);
  const [ error, setError ] = useState<string>();

  const changeTestKitReceived = useCallback((withKitId: string) => {
    setIsLoading(true);
    setError('');

    patchTestKitReceived(withKitId)
      .then((data) => {
        onUpdated(withKitId, !!data.data.testKitReceived);
      })
      .catch(error => setError(error?.response?.data?.message || t('errors.server_error_try_again')))
      .finally(() => {
        setIsLoading(false);
      });
  }, [ t, onUpdated ]);

  return {
    changeTestKitReceived,
    isLoading,
    error,
  };
};

export const useChangeLatestTestKitReceived = (onUpdated: (userId: string, testKitReceived: boolean) => void) => {
  const { t } = useTranslation();

  const [ isLoading, setIsLoading ] = useState(false);
  const [ error, setError ] = useState<string>();

  const changeLatestTestKitReceived = useCallback((userId: string) => {
    setIsLoading(true);
    setError('');

    patchLatestTestKitReceived(userId)
      .then((data) => {
        onUpdated(userId, !!data.data.testKitReceived);
      })
      .catch(error => setError(error?.response?.data?.message || t('errors.server_error_try_again')))
      .finally(() => {
        setIsLoading(false);
      });
  }, [ t, onUpdated ]);

  return {
    changeLatestTestKitReceived,
    isLoading,
    error,
  };
};
