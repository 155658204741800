import { Organization, Site } from 'api/Organizations/types';
import { UserAccessLevel } from 'api/User/types';
import { FormTextField } from 'common/components';
import KitIdInput from 'common/components/KitIdInput/KitIdInput';
import { disallowComma } from 'common/helpers/disallowComma';
import { COUNTRIES_NAMES, STATES } from 'common/constants/countries';
import { MALE, FEMALE, OTHER, UNSPECIFIED } from 'common/constants/genders';
import useAddUserForm from 'components/AdminDashboard/components/UserAddPage/hooks';
import useLocalizedProfile from 'components/Dashboard/components/ProfilePage/hooks/useLocalizedProfile';
import { UserPageProps } from 'components/AdminDashboard/components/UsersPage/types';
import { RootState } from 'store';

import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FormikProvider } from 'formik';
import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, Grid, MenuItem, Paper, TextField, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';

const UserAddPage: React.FC<UserPageProps> = ({ isClinicalPatientPage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateBack = () => navigate(-1);
  const { organizations } = useSelector((store: RootState) => store.organizations);
  const { myUser: { organization, accessRights } } = useSelector((store: RootState) => store.user);
  const userOrgId = organization?.id;
  const [ isDatePickerOpened, setIsDatePickerOpened ] = useState<boolean>(false);
  const { formik, isSubmitting, submitError } = useAddUserForm(navigateBack, !!isClinicalPatientPage);
  const { getLocalizedGender } = useLocalizedProfile();
  const organizationValue = userOrgId || formik.values.organizationId;

  const openDatePicker = () => setIsDatePickerOpened(true);
  const closeDatePicker = () => setIsDatePickerOpened(false);

  const onCountryChangeHandler = useCallback((_, newValue: string | null) => {
    formik.setFieldValue('country', newValue ?? '');
  }, [ formik ]);

  const onStateChangeHandler = useCallback((_, newValue: string | null) => {
    formik.setFieldValue('state', newValue ?? '');
  }, [ formik ]);

  const [ sites, setSites ] = useState<Site[]>([]);
  const onOrgChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSites(organizations.find(o => o.id === Number(e.target.value))?.sites || []);
    formik.setFieldValue('organizationId', e.target.value ?? '');
    formik.setFieldValue('organizationSiteId', '');
  }, [ formik, organizations ]);
  
  return (
    <Paper sx={{ maxWidth: '100%' }}>
      <Box
        sx={{ backgroundColor: '#fff' }}
        display="flex"
        flexDirection="column"
        borderRadius={4}
        padding="48px 64px 100px"
      >

        <Grid item xs={12} marginBottom={3}>
          <Button variant="text" startIcon={<ArrowBackIcon />} onClick={navigateBack}>
            Back to admin dashboard
          </Button>
        </Grid>

        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>

              <Grid item xs={12}>

                <Accordion defaultExpanded square>

                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">{isClinicalPatientPage ? 'Patient' : 'User'} info</Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid container spacing={2}>

                      <Grid item xs={12} container justifyContent="space-between" display="flex" spacing={2}>
                        <Grid item xs={6}>
                          <FormTextField
                            id="firstName"
                            name="firstName"
                            label={t('dashboard.profile.first_name')}
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            error={formik.errors.firstName}
                            touched={formik.touched.firstName}
                            disabled={isSubmitting}
                            onKeyDown={disallowComma}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <FormTextField
                            id="lastName"
                            name="lastName"
                            label={t('dashboard.profile.last_name')}
                            type="lastName"
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            error={formik.errors.lastName}
                            touched={formik.touched.lastName}
                            disabled={isSubmitting}
                            onKeyDown={disallowComma}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container justifyContent="space-between" display="flex" spacing={2}>
                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <TextField
                              id="gender"
                              select
                              value={formik.values.gender.toLowerCase()}
                              label={t('dashboard.profile.gender')}
                              name="gender"
                              size="small"
                              disabled={isSubmitting}
                              onChange={formik.handleChange}
                              helperText={formik.touched.gender && formik.errors.gender}
                              error={formik.touched.gender && Boolean(formik.errors.gender)}
                              data-testid="input-gender"
                            >
                              <MenuItem value={MALE}>{getLocalizedGender('male')}</MenuItem>
                              <MenuItem value={FEMALE}>{getLocalizedGender('female')}</MenuItem>
                              <MenuItem value={OTHER}>{getLocalizedGender('other')}</MenuItem>
                              <MenuItem value={UNSPECIFIED}>{getLocalizedGender('unspecified')}</MenuItem>
                            </TextField>
                          </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                          <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePicker
                              open={isDatePickerOpened}
                              label={t('dashboard.profile.date_of_birth')}
                              value={formik.values.birthdate}
                              onChange={(newValue) => {
                                formik.setFieldValue('birthdate', newValue);
                              }}
                              onOpen={openDatePicker}
                              onClose={closeDatePicker}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  helperText={formik.touched.birthdate && formik.errors.birthdate}
                                  error={formik.touched.birthdate && Boolean(formik.errors.birthdate)}
                                  size="small"
                                  fullWidth
                                  data-testid="input-birthdate"
                                  onClick={openDatePicker}
                                />
                              )}
                              disabled={isSubmitting}
                              disableFuture
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container justifyContent="space-between" display="flex" spacing={2}>
                        <Grid item xs={6}>
                          <FormTextField
                            id="email"
                            name="email"
                            label={t('general.email')}
                            type="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.errors.email}
                            touched={formik.touched.email}
                            disabled={isSubmitting}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <FormTextField
                            id="phoneNumber"
                            name="phoneNumber"
                            label="Phone"
                            value={formik.values.phoneNumber}
                            onChange={formik.handleChange}
                            error={formik.errors.phoneNumber}
                            touched={formik.touched.phoneNumber}
                            disabled={isSubmitting}
                            onKeyDown={disallowComma}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container justifyContent="space-between" display="flex" spacing={2}>
                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <TextField
                              id="organizationId"
                              name="organizationId"
                              label="Organization"
                              select
                              value={accessRights === UserAccessLevel.SUPER_ADMIN ? formik.values.organizationId : organizationValue}
                              onChange={onOrgChangeHandler}
                              error={formik.touched.organizationId && Boolean(formik.errors.organizationId)}
                              helperText={formik.touched.organizationId && formik.errors.organizationId}
                              disabled={isSubmitting || (!!userOrgId && accessRights !== UserAccessLevel.SUPER_ADMIN)}
                              size="small"
                            >
                              <MenuItem value=""><em>None</em></MenuItem>
                              {organizations.map((item: Organization) => <MenuItem key={item.name} value={item.id}>{item.name}</MenuItem>)}
                            </TextField>
                          </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <TextField
                              id="organizationSiteId"
                              select
                              value={formik.values.organizationSiteId ?? ''}
                              label="Site"
                              name="organizationSiteId"
                              size="small"
                              disabled={isSubmitting || !formik.values.organizationId}
                              onChange={formik.handleChange}
                            >
                              <MenuItem value=""><em>None</em></MenuItem>
                              {sites.map((item: Site) => <MenuItem key={item.name} value={item.id}>{item.name}</MenuItem>)}
                            </TextField>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container justifyContent="space-between" display="flex" spacing={2}>
                        <Grid item xs={6}>
                          <Autocomplete
                            onChange={onCountryChangeHandler}
                            value={formik.values.country}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label={t('dashboard.profile.country')}
                                name="country"
                                disabled={isSubmitting}
                                error={formik.touched.country && Boolean(formik.errors.country)}
                                helperText={formik.touched.country && formik.errors.country}
                                size="small"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password',
                                }}
                              />
                            )}
                            options={COUNTRIES_NAMES}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container justifyContent="space-between" display="flex" spacing={2}>
                        <Grid item xs={6}>
                          <Autocomplete
                            onChange={onStateChangeHandler}
                            value={formik.values.state}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="US state"
                                name="state"
                                disabled={isSubmitting}
                                error={formik.touched.state && Boolean(formik.errors.state)}
                                helperText={formik.touched.state && formik.errors.state}
                                size="small"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password',
                                }}
                                data-testid="input-state"
                              />
                            )}
                            options={STATES}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            id="city"
                            name="city"
                            label="US city"
                            variant="outlined"
                            size="small"
                            inputProps={{
                              autoComplete: 'none',
                            }}
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            error={formik.touched.city && Boolean(formik.errors.city)}
                            helperText={formik.touched.city && formik.errors.city}
                            disabled={isSubmitting}
                            data-testid="input-city"
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="street1"
                          name="street1"
                          label="US Street address line"
                          variant="outlined"
                          size="small"
                          value={formik.values.street1}
                          onChange={formik.handleChange}
                          error={formik.touched.street1 && Boolean(formik.errors.street1)}
                          helperText={formik.touched.street1 && formik.errors.street1}
                          data-testid="input-street1"
                          disabled={isSubmitting}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} container justifyContent="space-between" display="flex" spacing={2}>
                        <Grid item xs={6}>
                          <FormTextField
                            id="zip"
                            name="zip"
                            label={t('dashboard.profile.address.zip')}
                            value={formik.values.zip}
                            onChange={formik.handleChange}
                            error={formik.errors.zip}
                            touched={formik.touched.zip}
                            disabled={isSubmitting}
                            onKeyDown={disallowComma}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <FormTextField
                            id="previousZipCodes"
                            name="previousZipCodes"
                            label="Previous US ZIP codes"
                            value={formik.values.previousZipCodes}
                            onChange={formik.handleChange}
                            error={formik.errors.previousZipCodes}
                            touched={formik.touched.previousZipCodes}
                            disabled={isSubmitting}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container justifyContent="space-between" display="flex" spacing={2}>
                        <Grid item xs={6}>
                          <KitIdInput
                            onChange={formik.handleChange}
                            disabled={isSubmitting}
                            value={formik.values.testKitId}
                            error={formik.touched.testKitId && Boolean(formik.errors.testKitId)}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <FormControlLabel
                            sx={{ span: { fontSize: '15px', marginLeft: { md: '-4px', lg: 0 } }, marginRight: 0 }}
                            control={(
                              <Checkbox
                                id="testKitReceived"
                                onChange={formik.handleChange}
                                value={formik.values.testKitReceived}
                                checked={formik.values.testKitReceived}
                              />
                            )}
                            label="Test Kit Received"
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container spacing={2}>
                        <Grid item xs={6}>
                          <FormTextField
                            id="mrn"
                            name="mrn"
                            label="MRN"
                            value={formik.values.mrn}
                            onChange={formik.handleChange}
                            error={formik.errors.mrn}
                            touched={formik.touched.mrn}
                            disabled={isSubmitting}
                          />
                        </Grid>
                      </Grid>

                    </Grid>
                  </AccordionDetails>

                </Accordion>

              </Grid>

              <Grid item xs={12}>

                <Accordion>

                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Sample info</Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid container spacing={2}>

                      <Grid item xs={12} container spacing={2}>
                        <Grid item xs={6}>
                          <FormTextField
                            id="sampleSampleId"
                            name="sampleSampleId"
                            label="Sample ID"
                            value={formik.values.sampleSampleId}
                            onChange={formik.handleChange}
                            error={formik.errors.sampleSampleId}
                            touched={formik.touched.sampleSampleId}
                            disabled={isSubmitting}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <FormTextField
                            id="sampleProviderName"
                            name="sampleProviderName"
                            label="Provider"
                            value={formik.values.sampleProviderName}
                            onChange={formik.handleChange}
                            error={formik.errors.sampleProviderName}
                            touched={formik.touched.sampleProviderName}
                            disabled={isSubmitting}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container spacing={2}>
                        <Grid item xs={6}>
                          <FormTextField
                            id="sampleProductLine"
                            name="sampleProductLine"
                            label="Product line"
                            value={formik.values.sampleProductLine}
                            onChange={formik.handleChange}
                            error={formik.errors.sampleProductLine}
                            touched={formik.touched.sampleProductLine}
                            disabled={isSubmitting}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <FormTextField
                            id="sampleAccount"
                            name="sampleAccount"
                            label="Account"
                            value={formik.values.sampleAccount}
                            onChange={formik.handleChange}
                            error={formik.errors.sampleAccount}
                            touched={formik.touched.sampleAccount}
                            disabled={isSubmitting}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container spacing={2}>
                        <Grid item xs={6}>
                          <FormTextField
                            id="sampleElisOrderId"
                            name="sampleElisOrderId"
                            label="ELIS order ID"
                            value={formik.values.sampleElisOrderId}
                            onChange={formik.handleChange}
                            error={formik.errors.sampleElisOrderId}
                            touched={formik.touched.sampleElisOrderId}
                            disabled={isSubmitting}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <FormTextField
                            id="sampleSpecimenType"
                            name="sampleSpecimenType"
                            label="Specimen type"
                            value={formik.values.sampleSpecimenType}
                            onChange={formik.handleChange}
                            error={formik.errors.sampleSpecimenType}
                            touched={formik.touched.sampleSpecimenType}
                            disabled={isSubmitting}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container spacing={2}>
                        <Grid item xs={6}>
                          <FormTextField
                            id="samplePanel"
                            name="samplePanel"
                            label="Panel"
                            value={formik.values.samplePanel}
                            onChange={formik.handleChange}
                            error={formik.errors.samplePanel}
                            touched={formik.touched.samplePanel}
                            disabled={isSubmitting}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <FormTextField
                            id="sampleDiagnosisCodes"
                            name="sampleDiagnosisCodes"
                            label="Diagnosis codes"
                            value={formik.values.sampleDiagnosisCodes}
                            onChange={formik.handleChange}
                            error={formik.errors.sampleDiagnosisCodes}
                            touched={formik.touched.sampleDiagnosisCodes}
                            disabled={isSubmitting}
                          />
                        </Grid>
                      </Grid>

                    </Grid>
                  </AccordionDetails>

                </Accordion>

              </Grid>

              { submitError && (
                <Grid item xs={12}>
                  <Typography color="error" variant="subtitle2">
                    {submitError}
                  </Typography>
                </Grid>
              )}

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="large"
                  sx={{ padding: '10px 50px' }}
                  type="submit"
                  disabled={isSubmitting}
                >
                  Add {isClinicalPatientPage ? 'clinical patient' : 'user'}
                </Button>
              </Grid>

            </Grid>
          </form>
        </FormikProvider>

      </Box>
    </Paper>
  );
};

export default UserAddPage;
