import { ReduxDispatch } from 'store';
import { getMyUserRequest } from 'api/User/api';
import { SET_MY_USER, SET_MY_USER_LOADING } from 'store/user/types';
import { SET_DIRECT_SHIPPING_STATUS, SET_RETURN_SHIPPING_STATUS } from 'store/shipping/types';
import { getMyShippings } from 'store/shipping/actions';

export const getMyUser = () => (dispatch: ReduxDispatch) => {
  dispatch({ type: SET_MY_USER_LOADING, value: true });

  getMyUserRequest()
    .then(({ data }) => {
      dispatch({ type: SET_MY_USER, user: data });

      if (data.directShippingStatus) {
        dispatch({ type: SET_DIRECT_SHIPPING_STATUS, data: data.directShippingStatus });
      }
      if (data.returnShippingStatus) {
        dispatch({ type: SET_RETURN_SHIPPING_STATUS, data: data.returnShippingStatus });
      }

      dispatch(getMyShippings(data.directShippingStatus === null, data.returnShippingStatus === null));
    })
    .catch(() => {})
    .finally(() => {
      dispatch({ type: SET_MY_USER_LOADING, value: false });
    });
};
