import { TransactionObjects } from 'api/Shipment/types';
import { DELIVERY_TYPE_FROM_USER, DELIVERY_TYPE_TO_USER_AND_RETURN } from 'api/Shipment/constants';

export const getDeliveryType = (transactions: TransactionObjects) => {
  if (transactions.directTransaction) {
    return DELIVERY_TYPE_TO_USER_AND_RETURN;
  }
  if (transactions.returnTransaction) {
    return DELIVERY_TYPE_FROM_USER;
  }
  return undefined;
};
