import { WithKit } from 'api/User/types';
import { useRenewShippingLabels } from 'components/AdminDashboard/components/ShippingStatusPage/components/RenewShippingLabelsModal/hooks';

import React from 'react';
import { Box, Button, Grid, Modal, Paper, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface Props {
  onCancel: () => void;
  onSave: (oldWithKitId: string, withKit: WithKit) => void;
  withKit: WithKit;
}

const RenewShippingLabelsModal: React.FC<Props> = ({ onCancel, onSave, withKit }) => {
  const { renewLabels, isLoading } = useRenewShippingLabels(onSave);

  return (
    <Modal open>
      <Paper
        sx={{
          borderRadius: '20px',
          elevation: 9,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
          padding: 3,
          width: { xs: '100%', md: '600px' },
        }}
        data-testid="modal-renew-labels"
      >
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="textXLBold">
                Renew shipping labels
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Are you sure you want to renew shipping labels?
              </Typography>
            </Grid>
            <Grid container spacing={2} item xs={12}>
              <Grid item>
                <LoadingButton variant="contained" onClick={() => renewLabels(withKit)} loading={isLoading} data-testid="button-save-renew">
                  Yes
                </LoadingButton>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={onCancel} disabled={isLoading}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Modal>
  );
};

export default RenewShippingLabelsModal;
