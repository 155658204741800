import { ShippingStatus, TrackStatus, TrackStatusLiteral, TransactionObjects } from 'api/Shipment/types';

export const getShippingProgress = (transactions?: TransactionObjects, returnTrackStatus?: ShippingStatus, directTrackStatus?: ShippingStatus) => {
  if (!transactions) {
    return 0;
  }

  // no transactions
  if (!transactions.directTransaction && !transactions.returnTransaction) {
    return 0;
  }

  // delivery only from user (first flow)
  if (!transactions.directTransaction) {
    if (!returnTrackStatus) {
      return 25;
    }

    if (isPreTransitStatus(returnTrackStatus.status)) {
      return 50;
    }

    if (isTransitStatus(returnTrackStatus.status)) {
      return 75;
    }

    if (isDeliveredStatus(returnTrackStatus.status)) {
      return 100;
    }
  }

  // delivery both ways (first flow)
  if (transactions.directTransaction && transactions.returnTransaction) {
    if (!directTrackStatus) {
      return 10;
    }

    if (returnTrackStatus && isDeliveredStatus(directTrackStatus.status)) {
      if (isPreTransitStatus(returnTrackStatus.status)) {
        return 70;
      }
      if (isTransitStatus(returnTrackStatus.status)) {
        return 80;
      }
      if (isDeliveredStatus(returnTrackStatus.status)) {
        return 100;
      }
    }

    if (isPreTransitStatus(directTrackStatus.status)) {
      return 20;
    }

    if (isTransitStatus(directTrackStatus.status)) {
      return 30;
    }

    if (isDeliveredStatus(directTrackStatus.status)) {
      return 50;
    }
  }

  return 0;
};

export const isDeliveredStatus = (status?: TrackStatus | TrackStatusLiteral) => {
  return status === TrackStatus.DELIVERED || status === TrackStatusLiteral.DELIVERED;
};

export const isTransitStatus = (status?: TrackStatus | TrackStatusLiteral) => {
  return status === TrackStatus.TRANSIT || status === TrackStatusLiteral.TRANSIT;
};

export const isPreTransitStatus = (status?: TrackStatus | TrackStatusLiteral) => {
  return status === TrackStatus.PRE_TRANSIT || status === TrackStatusLiteral.PRE_TRANSIT;
};

export const isFailureStatus = (status?: TrackStatus | TrackStatusLiteral) => {
  return status === TrackStatus.FAILURE || status === TrackStatusLiteral.FAILURE;
};

export const isReturnedStatus = (status?: TrackStatus | TrackStatusLiteral) => {
  return status === TrackStatus.RETURNED || status === TrackStatusLiteral.RETURNED;
};
