import { ShippingStatus, TransactionObject } from 'api/Shipment/types';
import {
  DontHaveKitSteps,
} from 'components/Dashboard/components/ShippingPage/components/TrackingPage/DontHaveKitFlow/types';
import { isDeliveredStatus, isPreTransitStatus } from 'common/helpers/shipping';

export const getActiveStepIndex = (
  directTransaction: TransactionObject | null,
  returnTransaction: TransactionObject | null,
  directShippingStatus?: ShippingStatus,
  returnShippingStatus?: ShippingStatus,
) => {
  if (!directTransaction || directTransaction.status === 'ERROR') {
    return DontHaveKitSteps.REQUEST;
  }
  // todo add some track status condition for real flow?
  if (returnShippingStatus && !isPreTransitStatus(returnShippingStatus.status)) {
    if (isDeliveredStatus(returnShippingStatus.status)) {
      return DontHaveKitSteps.COMPLETED;
    } else {
      return DontHaveKitSteps.RETURN_TRANSIT;
    }
  } else if (directShippingStatus && !isPreTransitStatus(directShippingStatus.status)) {
    if (!isDeliveredStatus(directShippingStatus.status)) {
      return DontHaveKitSteps.DIRECT_TRANSIT;
    } else {
      return DontHaveKitSteps.DIRECT_DELIVERED;
    }
  }

  return DontHaveKitSteps.PROCESSING;
};

export const getTrackDescription = (directShippingStatus?: ShippingStatus, returnShippingStatus?: ShippingStatus) => {
  if (returnShippingStatus) {
    return returnShippingStatus.statusDetails;
  } else if (directShippingStatus) {
    return directShippingStatus.statusDetails;
  }
  return '';
};
