import { WithKit } from 'api/User/types';
import KitIdInput from 'common/components/KitIdInput/KitIdInput';
import { useAddTestKit } from 'components/AdminDashboard/components/ShippingStatusPage/components/AddTestKitModal/hooks';

import { Box, Button, FormHelperText, Grid, Modal, TextField, Paper, Typography } from '@mui/material';
import React from 'react';
import { LoadingButton } from '@mui/lab';

interface Props {
  onCancel: () => void;
  onSave: (withKit: WithKit) => void;
}

const AddTestKitModal: React.FC<Props> = ({ onCancel, onSave }) => {
  const [ userId, setUserId ] = React.useState('');
  const [ userIdError, setUserIdError ] = React.useState('');
  const [ kitId, setKitId ] = React.useState('');

  const { addTestKit, isLoading, error } = useAddTestKit(onSave);

  const handleUserIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserIdError('');
    setUserId(event.target.value);
  };
  
  const handleKitIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKitId(event.target.value);
  };

  const handleSave = () => {
    if (!userId) {
      setUserIdError('Enter user id');
      return;
    }

    addTestKit(Number(userId), kitId);
  };

  return (
    <Modal open>

      <Paper
        sx={{
          borderRadius: '20px',
          elevation: 9,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
          padding: 3,
          width: { xs: '100%', md: '600px' },
        }}
        data-testid="modal-add-test-kit"
      >

        <Box>

          <Grid container spacing={3}>

            <Grid item xs={12}>
              <Typography variant="textXLBold">
                Add a new test kit
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="user-id"
                value={userId}
                onChange={handleUserIdChange}
                label="User id"
                error={Boolean(userIdError)}
                size="small"
                disabled={isLoading}
                sx={{ width: '100%' }}
                data-testid="input-user-id"
              />
              {userIdError && (
                <FormHelperText error data-testid="error-user-id">
                  {userIdError}
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <KitIdInput onChange={handleKitIdChange} disabled={isLoading} value={kitId} />
            </Grid>

            {error && (
              <Grid item>
                <FormHelperText error data-testid="error-add-kit">
                  {error}
                </FormHelperText>
              </Grid>
            )}

            <Grid container spacing={2} item xs={12}>
              <Grid item>
                <LoadingButton variant="contained" onClick={handleSave} loading={isLoading} data-testid="button-add-kit-id">
                  Add
                </LoadingButton>
              </Grid>

              <Grid item>
                <Button variant="outlined" onClick={onCancel} disabled={isLoading}>
                  Cancel
                </Button>
              </Grid>

            </Grid>

          </Grid>

        </Box>

      </Paper>

    </Modal>
  );
};

export default AddTestKitModal;
