import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  value: string;
  error?: boolean;
  showTooltip?: boolean;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: 360,
    fontSize: '0.8rem',
  },
}));

const KitIdInput: React.FC<Props> = ({ onChange, value, error, disabled, showTooltip = true }) => {
  return (
    <TextField
      id="testKitId"
      name="testKitId"
      label="Test kit id"
      value={value}
      onChange={onChange}
      error={error}
      size="small"
      disabled={disabled}
      sx={{ width: '100%' }}
      data-testid="input-kit-id"
      InputProps={{
        endAdornment: (showTooltip ? (
          <InputAdornment position="end">
            <HtmlTooltip
              title={(
                <>
                  The following formats are supported:
                  <ul>
                    <li>US:01:5ddfb06b-97ba4127-b3b48653-f634ab59</li>
                    <li>USB:82b3b92d3c4b4d698692d77e15a65ee9</li>
                    <li>91575719591136</li>
                  </ul>
                </>
              )}
            >
              <HelpOutline />
            </HtmlTooltip>
          </InputAdornment>
        ) : null),
      }}
    />
  );
};

export default KitIdInput;
