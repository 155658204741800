import { axiosInstance } from 'api/instance';
import { GetTracksParams, Track, TransactionObjects } from 'api/Shipment/types';
import { WithKit } from 'api/User/types';

import { AxiosResponse } from 'axios';

export const getTransactions = (): Promise<AxiosResponse<TransactionObjects>> => {
  return axiosInstance.get<TransactionObjects>('users/transactions');
};

export const createTransaction = (deliveryType: number): Promise<AxiosResponse<TransactionObjects>> => {
  return axiosInstance.post<TransactionObjects>('shippo/transaction', { deliveryType });
};

export const recreateTransaction = (withKitId: string, deliveryType: number): Promise<AxiosResponse<WithKit>> => {
  return axiosInstance.post<WithKit>(`shippo/recreate-transaction/${withKitId}`, { deliveryType });
};

export const getTrack = (params: GetTracksParams): Promise<AxiosResponse<Track>> => {
  return axiosInstance.post<Track>('shippo/tracks', params);
};

export const patchTestKitReceived = (withKitId: string): Promise<AxiosResponse> => {
  return axiosInstance.put(`shippo/switch-test-kit-received/${withKitId}`);
};

export const updateUserKitRequest = (id: string, kitId: string): Promise<AxiosResponse> => {
  return axiosInstance.put(`shippo/update-kit/${id}`, { kitId });
};

export const createTestKit = (userId: number, kitId?: string): Promise<AxiosResponse<WithKit>> => {
  return axiosInstance.post<WithKit>(`shippo/transaction/${userId}`, { kitId });
};
