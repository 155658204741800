import { RegistrationStatus, User, UserAccessLevel } from 'api/User/types';
import { Organization, Site } from 'api/Organizations/types';
import { FormTextField } from 'common/components';
import KitIdInput from 'common/components/KitIdInput/KitIdInput';
import { COUNTRIES_NAMES, STATES } from 'common/constants/countries';
import { disallowComma } from 'common/helpers/disallowComma';
import { FEMALE, MALE, OTHER, UNSPECIFIED } from 'common/constants/genders';
import useLocalizedProfile from 'components/Dashboard/components/ProfilePage/hooks/useLocalizedProfile';
import useUpdateUserForm from 'components/AdminDashboard/components/UserEditPage/components/UserEditForm/hooks';
import { getEHRStatusLabel } from 'components/AdminDashboard/components/UsersPage/components/EHRColumn/helpers';
import { UserPageProps } from 'components/AdminDashboard/components/UsersPage/types';
import { RootState } from 'store';

import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { FormikProvider } from 'formik';
import { Autocomplete, Button, FormControl, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';
import { useSelector } from 'react-redux';

interface Props extends UserPageProps {
  user: User;
}

const UserEditForm: React.FC<Props> = ({ user, isClinicalPatientPage }) => {
  const { t } = useTranslation();
  const { getLocalizedGender } = useLocalizedProfile();
  const { formik, submitError, isSubmitting } = useUpdateUserForm(user);

  const { organizations } = useSelector((store: RootState) => store.organizations);
  const { myUser: { organization, accessRights } } = useSelector((store: RootState) => store.user);
  const userOrgId = organization?.id;

  const [ isDatePickerOpened, setIsDatePickerOpened ] = useState<boolean>(false);
  const openDatePicker = () => setIsDatePickerOpened(true);
  const closeDatePicker = () => setIsDatePickerOpened(false);

  const [ isFormEditable, setIsFormEditable ] = useState<boolean>(false);
  const onEditClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsFormEditable(true);
  };

  const onCountryChangeHandler = useCallback((_, newValue: string | null) => {
    formik.setFieldValue('country', newValue ?? '');
  }, [ formik ]);

  const onStateChangeHandler = useCallback((_, newValue: string | null) => {
    formik.setFieldValue('state', newValue ?? '');
  }, [ formik ]);

  const [ sites, setSites ] = useState<Site[]>([]);
  const onOrgChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSites(organizations.find(o => o.id === Number(e.target.value))?.sites || []);
    formik.setFieldValue('organizationId', e.target.value ?? '');
    formik.setFieldValue('organizationSiteId', '');
  }, [ formik, organizations ]);

  useEffect(() => {
    setSites(organizations.find(o => o.id === user.organization?.id)?.sites || []);
  }, [ organizations, setSites, user.organization?.id ]);
  
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} container justifyContent="space-between" display="flex" spacing={2}>
            <Grid item xs={6}>
              <FormTextField
                id="firstName"
                name="firstName"
                label={t('dashboard.profile.first_name')}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.errors.firstName}
                touched={formik.touched.firstName}
                disabled={isSubmitting || !isFormEditable}
                onKeyDown={disallowComma}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                id="lastName"
                name="lastName"
                label={t('dashboard.profile.last_name')}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.errors.lastName}
                touched={formik.touched.lastName}
                disabled={isSubmitting || !isFormEditable}
                onKeyDown={disallowComma}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent="space-between" display="flex" spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  select
                  id="gender"
                  name="gender"
                  value={formik.values.gender?.toLowerCase()}
                  label={t('dashboard.profile.gender')}
                  size="small"
                  disabled={isSubmitting || !isFormEditable}
                  onChange={formik.handleChange}
                  helperText={formik.touched.gender && formik.errors.gender}
                  error={formik.touched.gender && Boolean(formik.errors.gender)}
                >
                  <MenuItem value={MALE}>{getLocalizedGender('male')}</MenuItem>
                  <MenuItem value={FEMALE}>{getLocalizedGender('female')}</MenuItem>
                  <MenuItem value={OTHER}>{getLocalizedGender('other')}</MenuItem>
                  <MenuItem value={UNSPECIFIED}>{getLocalizedGender('unspecified')}</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  open={isDatePickerOpened}
                  label={t('dashboard.profile.date_of_birth')}
                  value={formik.values.dob}
                  onChange={(newValue) => {
                    formik.setFieldValue('dob', newValue);
                  }}
                  onOpen={openDatePicker}
                  onClose={closeDatePicker}
                  renderInput={params => (
                    <TextField
                      {...params}
                      helperText={formik.touched.dob && formik.errors.dob}
                      error={formik.touched.dob && Boolean(formik.errors.dob)}
                      size="small"
                      fullWidth
                      onClick={openDatePicker}
                    />
                  )}
                  disabled={isSubmitting || !isFormEditable}
                  disableFuture
                  disableMaskedInput
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent="space-between" display="flex" spacing={2}>
            <Grid item xs={6}>
              <FormTextField
                id="username"
                name="username"
                label={t('general.email')}
                type="email"
                value={formik.values.username}
                onChange={formik.handleChange}
                error={formik.errors.username}
                touched={formik.touched.username}
                disabled={isSubmitting || user.registrationStatus === RegistrationStatus.ACTIVATED || !isFormEditable}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                id="phoneNumber"
                name="phoneNumber"
                label="Phone"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                disabled={isSubmitting || !isFormEditable}
                error={formik.errors.phoneNumber}
                touched={formik.touched.phoneNumber}
                onKeyDown={disallowComma}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} container justifyContent="space-between" display="flex" spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="organizationId"
                  select
                  value={formik.values.organizationId ?? ''}
                  label="Organization"
                  name="organizationId"
                  size="small"
                  disabled={isSubmitting || !isFormEditable || (!!userOrgId && accessRights !== UserAccessLevel.SUPER_ADMIN)}
                  onChange={onOrgChangeHandler}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  {organizations.map((item: Organization) => <MenuItem key={item.name} value={item.id}>{item.name}</MenuItem>)}
                </TextField>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="organizationSiteId"
                  select
                  value={formik.values.organizationSiteId ?? ''}
                  label="Site"
                  name="organizationSiteId"
                  size="small"
                  disabled={isSubmitting || !isFormEditable || !formik.values.organizationId}
                  onChange={formik.handleChange}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  {sites.map((item: Site) => <MenuItem key={item.name} value={item.id}>{item.name}</MenuItem>)}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12} container justifyContent="space-between" display="flex" spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                onChange={onCountryChangeHandler}
                value={formik.values.country}
                disabled={isSubmitting || !isFormEditable}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('dashboard.profile.country')}
                    name="country"
                    error={formik.touched.country && Boolean(formik.errors.country)}
                    helperText={formik.touched.country && formik.errors.country}
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
                options={COUNTRIES_NAMES}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} container justifyContent="space-between" display="flex" spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                onChange={onStateChangeHandler}
                value={formik.values.state}
                disabled={isSubmitting || !isFormEditable}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="US state"
                    name="state"
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    helperText={formik.touched.state && formik.errors.state}
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                    data-testid="input-state"
                  />
                )}
                options={STATES}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="city"
                name="city"
                label="US city"
                variant="outlined"
                size="small"
                inputProps={{
                  autoComplete: 'none',
                }}
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
                disabled={isSubmitting || !isFormEditable}
                data-testid="input-city"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="street1"
              name="street1"
              label="US Street address line"
              variant="outlined"
              size="small"
              value={formik.values.street1}
              onChange={formik.handleChange}
              error={formik.touched.street1 && Boolean(formik.errors.street1)}
              helperText={formik.touched.street1 && formik.errors.street1}
              data-testid="input-street1"
              disabled={isSubmitting || !isFormEditable}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} container justifyContent="space-between" display="flex" spacing={2}>
            <Grid item xs={6}>
              <FormTextField
                id="zip"
                name="zip"
                label={t('dashboard.profile.address.zip')}
                value={formik.values.zip}
                onChange={formik.handleChange}
                error={formik.errors.zip}
                touched={formik.touched.zip}
                disabled={isSubmitting || !isFormEditable}
                onKeyDown={disallowComma}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                id="previousZipCodes"
                name="previousZipCodes"
                label="Previous US ZIP codes"
                value={formik.values.previousZipCodes}
                onChange={formik.handleChange}
                error={formik.errors.previousZipCodes}
                touched={formik.touched.previousZipCodes}
                disabled={isSubmitting || !isFormEditable}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent="space-between" display="flex" spacing={2}>
            <Grid item xs={6}>
              <KitIdInput onChange={formik.handleChange} disabled value={user.kitId || ''} showTooltip={false} />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                id="healthGorillaId"
                name="healthGorillaId"
                label="Health Gorilla Patient ID"
                value={user.healthGorillaId || ''}
                disabled
                onChange={noop}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent="space-between" display="flex" spacing={2}>
            <Grid item xs={6}>
              <FormTextField
                id="mrn"
                name="mrn"
                label="MRN"
                value={formik.values.mrn}
                onChange={formik.handleChange}
                error={formik.errors.mrn}
                touched={formik.touched.mrn}
                disabled={isSubmitting || !isFormEditable}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent="space-between" display="flex" spacing={2}>
            <Grid item xs={6}>
              <FormTextField
                id="reportStatus"
                name="reportStatus"
                label="Ancestry report status"
                value={user.ancestries.length ? 'Generated' : 'Pending'}
                disabled
                onChange={noop}
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                id="ehr"
                name="ehr"
                label="EHR Status"
                value={getEHRStatusLabel(user.ehrStatus)}
                disabled
                onChange={noop}
                variant="standard"
              />
            </Grid>
          </Grid>
          { submitError && (
            <Grid item xs={12}>
              <Typography color="error" variant="subtitle2">
                {submitError}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} marginTop={2}>
            {isFormEditable ? (
              <Button type="submit" variant="contained" size="large" disabled={isSubmitting}>
                Update {isClinicalPatientPage ? 'clinical patient' : 'user' }
              </Button>
            ) : (
              <Button variant="contained" size="large" onClick={onEditClick}>
                Edit
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </FormikProvider>
  );
};

export default UserEditForm;
