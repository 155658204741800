import { useChangeTestKitReceived, useChangeLatestTestKitReceived } from 'common/components/ChangeTestKitReceivedModal/hooks';

import React from 'react';
import { Box, Button, Grid, Modal, Paper, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface Props {
  onCancel: () => void;
  id: string;
  onSave: (id: string, testKitReceived: boolean) => void;
  changeLatest?: boolean;
}

const ChangeTestKitReceivedModal: React.FC<Props> = ({ onCancel, id, onSave, changeLatest = false }) => {
  const { changeTestKitReceived, isLoading: isChanging } = useChangeTestKitReceived(onSave);
  const { changeLatestTestKitReceived, isLoading: isLatestChanging } = useChangeLatestTestKitReceived(onSave);
  const isLoading = isChanging || isLatestChanging;
  const onChange = changeLatest ? changeLatestTestKitReceived : changeTestKitReceived;

  return (
    <Modal open>
      <Paper
        sx={{
          borderRadius: '20px',
          elevation: 9,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
          padding: 3,
          width: { xs: '100%', md: '600px' },
        }}
        data-testid="modal-change-test-kit-received"
      >
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="textXLBold">
                Change Test Kit Received
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Are you sure you want to change the status?
              </Typography>
            </Grid>
            <Grid container spacing={2} item xs={12}>
              <Grid item>
                <LoadingButton variant="contained" onClick={() => onChange(id)} loading={isLoading} data-testid="button-change-test-kit">
                  Yes
                </LoadingButton>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={onCancel} disabled={isLoading}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Modal>
  );
};

export default ChangeTestKitReceivedModal;
