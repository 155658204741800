import { ShippingStatus, Transaction } from 'api/Shipment/types';
import { CurrentShipping, ShippingDirection } from 'components/AdminDashboard/components/ShippingStatusPage/types';
import { isDeliveredStatus, isFailureStatus, isPreTransitStatus, isReturnedStatus, isTransitStatus } from 'common/helpers/shipping';

export const getCurrentShipping = (
  directTransaction: Transaction | null,
  returnTransaction: Transaction | null,
): CurrentShipping => {
  if ((!directTransaction?.shippingStatus || isDeliveredStatus(directTransaction.shippingStatus.status)) && returnTransaction?.shippingStatus) {
    return {
      direction: ShippingDirection.RETURN,
      status: { ...returnTransaction.shippingStatus },
    };
  }
  
  if (directTransaction?.shippingStatus) {
    return {
      direction: ShippingDirection.DIRECT,
      status: { ...directTransaction.shippingStatus! },
    };
  }

  return { direction: ShippingDirection.NOT_STARTED, status: null };
};

export const isDeliveredFromUser = (shipping: CurrentShipping) => {
  return shipping.direction === ShippingDirection.RETURN && isDeliveredStatus(shipping.status?.status);
};

export const getDirectionLabel = (status: ShippingDirection) => {
  switch (status) {
    case ShippingDirection.DIRECT:
      return 'to user';

    case ShippingDirection.RETURN:
      return 'from user';

    case ShippingDirection.NOT_STARTED:
      return 'Not started';
  }
};

export const getStatusLabel = (status: ShippingStatus | null) => {
  if (!status?.status) {
    return '';
  }

  if (isPreTransitStatus(status.status)) {
    return 'Pre transit';
  } else if (isTransitStatus(status.status)) {
    return 'Transit';
  } else if (isDeliveredStatus(status.status)) {
    return 'Delivered';
  } else if (isReturnedStatus(status.status)) {
    return 'Returned';
  } else if (isFailureStatus(status.status)) {
    return 'Failure';
  }
};

export const isRenewingAllowed = (shipping: CurrentShipping) => {
  const { direction, status } = shipping;
  const st = status?.status;

  return direction === ShippingDirection.NOT_STARTED || isPreTransitStatus(st) || isReturnedStatus(st) || isFailureStatus(st);
};
