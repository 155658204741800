import { ShippingStatus, Transaction } from 'api/Shipment/types';
import { Ancestry, AncestryProvider } from 'api/Reports/types';
import { Sample } from 'api/Samples/types';
import { Optional } from 'common/types/Utils';
import { System } from 'common/components/MeasurementSystem/types';
import { Organization, Site } from 'api/Organizations/types';
import { FreezerworksStatus, SyncStatus } from 'common/types/Freezerworks';

export interface User {
  id: number;
  accessRights: UserAccessLevel;
  firstName: string;
  lastName: string;
  username: string | null;
  gender?: string
  dob?: string;
  race?: string;
  mailingAddress: MailingAddress;
  survey: Survey | null;
  surveyCompletedPercentage: number;
  surveyVersion: number | null;
  signature: string;
  parentSignature: string | null;
  kitId: string | null;
  returnShippingStatus: ShippingStatus | null;
  directShippingStatus: ShippingStatus | null;
  ancestryFiles: AncestryFile[];
  ancestries: Ancestry[];
  createdAt: string;
  partnerId: string | null;
  partnerName: string | null;
  utmParams: UTMParams | null;
  registrationStatus: RegistrationStatus;
  providerId: string | null;
  providerName: string | null;
  ehrStatus: EHRStatus;
  organization: Organization | null;
  organizationSite: Site | null;
  testKitReceived: boolean | null;
  healthGorillaId: string | null;
  samples: Sample[];
  phoneNumber: string | null;
  mrn: string | null;
  freezerworksStatus: FreezerworksStatus;
}

type UserFromListRequest = Pick<User,
  'id' |
  'username' |
  'firstName' |
  'lastName' |
  'dob' |
  'gender' |
  'kitId' |
  'registrationStatus' |
  'ehrStatus' |
  'organizationSite' |
  'testKitReceived' |
  'accessRights' |
  'utmParams' |
  'returnShippingStatus' |
  'createdAt' |
  'samples' |
  'mailingAddress' |
  'freezerworksStatus'
> & {
  organizationId: number | null;
  organizationName: string | null;
};

export interface UserView extends Omit<UserFromListRequest, | 'firstName' | 'lastName'> {
  firstName: string | null; // If a user is a data science admin then they must not see these fields for hipaa compliance reasons
  lastName: string | null;
  hgPipelineError?: string;
}

export interface UTMParams {
  id?: string;
  source?: string;
  medium?: string;
  campaign?: string;
}

export enum EHRStatus {
  EMPTY = '',
  NOT_PULLED = 'NOT_PULLED',
  PULLED = 'PULLED',
  MULTIPLE_MATCHES = 'MULTIPLE_MATCHES',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  NO_EHR_DATA = 'NO_EHR_DATA',
  PATIENT_CHART_ERROR_454 = 'PATIENT_CHART_ERROR_454',
  CAN_NOT_IMPORT = 'CAN_NOT_IMPORT',
}

export enum RegistrationStatus {
  ACTIVATED = 'Activated',
  REGISTERED = 'Registered',
}

export enum UserAccessLevel {
  USER,
  ADMIN,
  PARTNER,
  DATA_SCIENCE_ADMIN,
  BIO_BANK_ADMIN,
  SUPER_ADMIN = 1000,
}

export interface MailingAddress {
  zip: string | null;
  state: string | null;
  city: string | null;
  street1: string | null;
  street2: string | null;
  country: string | null;
  previousZipCodes: string[];
}

export type MailingAddressKey = keyof Omit<MailingAddress, 'previousZipCodes'>;

export interface PartnerInfo {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  dob: string;
  activatedAt: string;
  gender: string;
  partnerName: string;
  partnerId: number;
}

export interface UpdateMyUserParams {
  gender?: string;
  dob?: string | null;
  race?: string;
  mailingAddress?: Optional<MailingAddress, 'previousZipCodes'>;
}

type Nullable<T> = { [K in keyof T]: T[K] | null };

export type SurveyV1 = Nullable<SurveyQuestionsV1>;
export type SurveyV2 = Nullable<SurveyQuestionsV2>;

export type Survey = SurveyV1 | SurveyV2;

export interface SurveyQuestionsV1 {
  age: number;
  height: { foots: number, inches: number, centimeters: number, system: System }; // should be feet
  weight: number;
  weightSystem: System;
  latinoOrigin: { value: string, other?: string };
  race: string[];
  origin: { [key: string]: string };
  bornPlace: BornPlace;
  grandparentsBornPlace: {
    [key: string]: BornPlace;
  };
  highBloodPressure: string;
  bloodPressureMeasurement: { systolic: number, diastolic: number };
  bloodPressureMedications: { value: string, name: string };
  longTermHealthConditions: { values: string[], other: string };
  familyLongTermHealthConditions: { value: string, conditions: FamilyHealthCondition[] | null };
  covidTested: string;
  covidVaccinated: string;
  covidVaccine: { value: string, other: string };
  covidSymptoms: { values: string[], other: string };
}

export interface HealthInfo {
  answer: string;
  values: string[];
  other: string;
}

export type SurveyQuestionsV2 = Pick<SurveyQuestionsV1, 'height' | 'weight' | 'weightSystem' | 'bornPlace' | 'grandparentsBornPlace'> & {
  ancestralOrigin: string;
  smoker: string;
  heartDisease: HealthInfo;
  pulmonaryDisease: HealthInfo;
  metabolicDisease: HealthInfo;
  liverDisorder: HealthInfo;
  digestiveDisorder: HealthInfo;
  kidneyDisease: HealthInfo;
  neurologicalDisorder: HealthInfo;
  mentalIllness: HealthInfo;
  skinDisease: HealthInfo;
  rheumaticDisease: HealthInfo;
  cancer: HealthInfo;
  infectiousDisease: HealthInfo;
};

export interface BornPlace {
  country: string;
  city: string;
  dontKnow?: boolean;
}

export interface FamilyHealthCondition {
  member: string;
  condition: string;
}

export interface AncestryFile {
  id: string;
  sourceFileUploadedAt: string;
  providerName: AncestryProvider;
}

export interface UpdateUserRequestParams {
  username: string;
  firstName: string;
  lastName: string;
  gender: string;
  dob: string;
  organizationId: number | null;
  organizationSiteId: string | null;
  phoneNumber: string;
  mailingAddress: Omit<MailingAddress, 'street2'>;
}

export interface CreateUserRequestParams {
  firstName: string;
  lastName: string;
  email: string;
  organizationId: string | null;
  organizationSiteId: string | null;
  birthdate: string;
  gender: string;
  phoneNumber: string;
  testKitId: string;
  testKitReceived: boolean;
  mrn: string;
  mailingAddress: Omit<MailingAddress, 'street2'>;
  sampleSampleId: string;
  sampleProviderName: string;
  sampleProductLine: string;
  sampleAccount: string;
  sampleElisOrderId: string;
  sampleSpecimenType: string;
  sampleDiagnosisCodes: string;
  samplePanel: string;
}

export interface PipelineExecutionResponseMessageIssue {
  severity: string;
  code: string;
  details?: {
    text: string;
  };
  diagnostics?: string;
}

export interface PipelineExecutionResponseMessage {
  resourceType: string;
  issue: PipelineExecutionResponseMessageIssue[];
}

export interface PipelineExecutionResponse {
  message: string;
  response?: string;
  responseMessage?: string;
  responseStatusCode?: number;
}

export enum RegistrationFilter {
  ACTIVATED = 'Activated',
  REGISTERED = 'Registered',
  ANY = '',
}

export enum TestKitShippedFilter {
  SHIPPED = 'Shipped',
  NONE = 'None',
  ANY = '',
}

export interface UsersFilter {
  userName?: string;
  onlyAdmins?: boolean;
  registration?: RegistrationFilter;
  ehrStatus?: EHRStatus;
  fwStatus?: SyncStatus;
  testKitShipped?: TestKitShippedFilter;
  partners?: number[],
  gender?: string,
  survey?: string,
}

export enum FileFormat {
  CSV = 'csv',
  TSV = 'tsv',
}

export interface WithKit {
  id: string;
  kitId: string | null;
  kitReceived: boolean | null;
  user: Pick<User, 'id' | 'firstName' | 'lastName' | 'username'>;
  directTransaction: Transaction | null;
  returnTransaction: Transaction | null;
}
