import { createTestKit } from 'api/Shipment/api';
import { WithKit } from 'api/User/types';

import { useState, useEffect } from 'react';

export const useAddTestKit = (onAdded: (withKit: WithKit) => void) => {
  const [ error, setError ] = useState('');
  const [ isLoading, setLoading ] = useState(false);

  useEffect(() => {
    return () => {
      setError('');
      setLoading(false);
    };
  }, []);

  const addTestKit = (userId: number, kitId?: string) => {
    setError('');
    setLoading(true);

    createTestKit(userId, kitId)
      .then(({ data }) => {
        onAdded(data);
      })
      .catch(error => setError(error?.response?.data?.message || 'Server error. Please try again.'))
      .finally(() => setLoading(false));
  };

  return {
    addTestKit,
    isLoading,
    error,
    setError,
  };
};
