import { ShippingStatus, TransactionObject } from 'api/Shipment/types';
import { HaveKitSteps } from 'components/Dashboard/components/ShippingPage/components/TrackingPage/HaveKitFlow/types';
import { isDeliveredStatus } from 'common/helpers/shipping';

export const getActiveStepIndex = (returnTransaction: TransactionObject | null, shippingStatus?: ShippingStatus) => {
  if (shippingStatus) {
    if (!isDeliveredStatus(shippingStatus.status)) {
      return HaveKitSteps.TRANSIT;
    } else {
      return HaveKitSteps.DELIVERED;
    }
  } else {
    return returnTransaction ? HaveKitSteps.PROCESSING : HaveKitSteps.GENERATE;
  }
};
